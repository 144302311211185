<template>
  <!-- :style="{ height: documentHeight + 'px' }" -->
  <div id="app" v-cloak>
    <!-- <navBar
      v-show="navShow"
      :title="title"
      :name="name"
      :isleftarrow="isleftarrow"
      :isShare="isShare"
      safe-area-inset-top
    ></navBar>-->
    <transition :name="transitionName">
      <keep-alive :include="keepAlive">
        <router-view class></router-view>
      </keep-alive>
    </transition>
    <!-- <TabBar v-if="isShow" :activeBar="activeBar"></TabBar> -->
    <TabBar v-if="isShow" :activeBar="activeBar"></TabBar>
    <van-dialog
      class="dialog_class"
      v-model="dialog.show"
      confirm-button-color="#000000"
      cancel-button-color="#000000"
      :show-cancel-button="dialog.showCancel"
      :show-confirm-button="dialog.showConfirm"
      cancel-button-text="关闭"
      :confirm-button-text="dialog.confirmText"
      @confirm="dialogConfirm"
    >
      <template #default>
        <template>
          <div class="dialog_content pay_success">
            <img
              v-show="dialog.failIcon"
              class="success_icon"
              src="@/assets/images/dialog/fail.png"
              alt
            />
            <img
              v-show="dialog.successIcon"
              class="success_icon"
              src="@/assets/images/dialog/success.png"
              alt
            />
            <span>{{ dialog.content }}</span>
          </div>
        </template>
      </template>
    </van-dialog>
  </div>
</template>
<script>
// import navBar from "@/components/NavBar";
import TabBar from "@/components/Footer";
import { setStore, getStore } from "@/utils/mUtils";
import { removeStore } from "./utils/mUtils";
// import { getLocation } from "@/utils/mapUtils";
// getLocation();
export default {
  name: "App",
  data() {
    return {
      dialog: {
        show: false,
        showCancel: false,
        showConfirm: true,
        confirmText: "返回首页",
        content: "",
        successIcon: false,
        failIcon: false,
      },
      welcomeShow: true,
      keepAlive: ["home", "my"], //需要缓存的页面 例如首页
      transitionName: "slide-right", //初始过渡动画方向
      activeBar: null,
      showTabBar: ["home", "my"],
      isShow: false,
      name: "",
      title: "",
      isleftarrow: "",
      isShare: false,
      documentHeight: window.innerHeight, //默认屏幕高度
      // showHeight: document.documentElement.clientHeight,
      navShow: true,
    };
  },
  components: {
    // navBar,
    TabBar,
  },
  created() {
    setStore("loglevel:webpack-dev-server", "SILENT");
    setStore("signIn", true);
    this.welcomeShow = true;
    // console.log(this.welcomeShow);
  },
  mounted() {
    this
      .initWXJSSDK
      //   {
      //   title:'聆课咨询学习平台',
      //   desc:'登顶行业头部的谋略',
      //   link:this.$BaseUrl+'home',
      //   imgUrl:'http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png',
      // }
      ();
    this.title = this.$route.meta.title;
    this.isleftarrow = this.$route.meta.isleftarrow;
    this.isShare = this.$route.meta.isShare;
    // window.onresize = () => {
    //   return (() => {
    //     this.showHeight = document.body.clientHeight;
    //     // alert(this.showHeight);
    //   })();
    // };
    this.$EventBus.$on("welcomeShow", () => {
      // A发送来的消息
      this.welcomeShow = false;
      // this.getUserInfoFunc();
    });
    this.$EventBus.$on("showDialog", (e) => {
      // 报名成功
      this.showDialog(e);
    });
  },
  watch: {
    $route(to, from) {
      // console.log("to", to);
      // console.log("from", from);
      this.title = to.meta.title;
      this.name = to.name;
      this.isleftarrow = to.meta.isleftarrow;
      this.isShare = this.$route.meta.isShare;
      if (this.showTabBar.includes(to.name)) {
        this.isShow = true;
        this.activeBar = this.showTabBar.indexOf(to.name);
      } else {
        this.isShow = false;
      }
      let isBack = this.$router.isBack; // 监听路由变化时的状态为前进还是后退

      if (isBack) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
      this.$router.isBack = false;
      if (to.name !== "HomeDetail") {
        this.$wx.ready(() => {
          this.$wx.updateAppMessageShareData({
            title: "聆课咨询学习平台",
            desc: "登顶行业头部的谋略",
            link: this.$BaseUrl + "home",
            imgUrl:
              "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png",
            success: () => {
              // 设置成功
              console.log("设置成功");
            },
          });
          this.$wx.updateTimelineShareData({
            title: "聆课咨询学习平台",
            desc: "登顶行业头部的谋略",
            link: this.$BaseUrl + "home",
            imgUrl:
              "http://testlemneross.lemner.com/2025/02/24/7ab54ef0-75aa-4193-88b9-89ba9c4281b6.png",
            success: () => {
              // 设置成功
              console.log("设置成功");
            },
          });
        });
      }
    },
    // showHeight: function() {
    //   if (this.documentHeight > this.showHeight) {
    //     // document.body.clientHeight = this.documentHeight;
    //     // this.hideshow = false;
    //     this.$EventBus.$emit("submitUp", true);
    //   } else {
    //     this.$EventBus.$emit("submitUp", false);
    //     // document.body.clientHeight = this.documentHeight
    //     // this.hideshow = true;
    //   }
    // }
  },
  methods: {
    dialogConfirm() {
      // 返回首页
      if (this.$route.path === "/home") {
        this.$EventBus.$emit("refreshHome");
      } else {
        this.$router.push({
          path: "/home",
        });
      }
    },
    showDialog(type) {
      switch (type) {
        case "applySuccess":
          this.dialog.show = true;
          this.dialog.showCancel = false;
          this.dialog.showConfirm = true;
          this.dialog.confirmText = "返回首页";
          this.dialog.content = "报名成功，待审核";
          this.dialog.successIcon = true;
          this.dialog.failIcon = false;
          break;
        case "paySuccess":
          this.dialog.show = true;
          this.dialog.showCancel = true;
          this.dialog.showConfirm = false;
          this.dialog.confirmText = "";
          this.dialog.content = "缴费成功";
          this.dialog.successIcon = true;
          this.dialog.failIcon = false;
          break;
        case "payFail":
          this.dialog.show = true;
          this.dialog.showCancel = true;
          this.dialog.showConfirm = false;
          this.dialog.confirmText = "";
          this.dialog.content = "缴费失败";
          this.dialog.failIcon = true;
          this.dialog.successIcon = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss">
.dialog_class {
  .dialog_content {
    padding: 0 120px;
    margin: 20px 0;
    width: 100%;
    height: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 30px;
    // font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    .success_icon {
      width: 37px;
      height: 37px;
      margin-right: 20px;
    }
  }
  .computed_line {
    display: inline-block;
    text-align: center;
    font-size: 32px;
    height: 60px;

    line-height: 60px;
  }
}
</style>
<style>
[v-cloak] {
  display: none;
}
.Router {
  position: absolute;
  /* height: 100%; */
  transition: all 0.377s ease;
  will-change: transform;
  /* top: 0; */
  backface-visibility: hidden;
  perspective: 1000;
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
#app {
  background: #fff;
}
#app #welcome_img {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
</style>
<style>
.ql-editor.ql-blank::before {
  color: #b2b2b2;
  font-style: normal;
}
.van-image.van-image-preview__image--vertical {
  width: 750px;
}
.van-image.van-image-preview__image--vertical img {
  width: 750px;
}
.van-list__error-text,
.van-list__finished-text,
.van-list__loading {
  color: #fff;
}
* {
  word-wrap: break-word;
  word-break: break-all;
}
.van-list__error-text,
.van-list__finished-text,
.van-list__loading {
  color: #333;
}
</style>
