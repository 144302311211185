import Vue from "vue";
import Bus  from "./Bus";
import wx from 'weixin-js-sdk';
import "@/styles/index.scss"; // global css
import "@/styles/variable.scss"; // variable css
// import { setStore } from "@/utils/mUtils";
import { generateUUID } from "@/utils";
import VueAliplayerV2 from "vue-aliplayer-v2";
Vue.use(VueAliplayerV2,{
    cssLink: 'https://g.alicdn.com/apsara-media-box/imp-web-player/2.23.1/skins/default/aliplayer-min.css',
    scriptSrc: 'https://g.alicdn.com/apsara-media-box/imp-web-player/2.23.1/aliplayer-h5-min.js'
});
import {
  ActionSheet,
  Badge,
  NavBar,
  Form,
  Button,
  Field,
  Overlay,
  Skeleton,
  Loading,
  Popup,
  Toast,
  Tab,
  Tabs,
  PullRefresh,
  List,
  Picker,
  Dialog,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  // ImagePreview,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Icon,
  Grid,
  GridItem,
  ShareSheet,
  Empty,
  Image as VanImage,
  Uploader,
  CountDown,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Sticky,
  Switch,
  Progress,
  Popover,
  NumberKeyboard,
  Area,
  NoticeBar,
  TreeSelect
} from "vant";

Toast.setDefaultOptions({ duration: 1000 });
// import VueAMap from "vue-amap";
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: "YOUR_KEY",
//   // 插件集合
//   plugin: [
//     "AMap.Autocomplete",
//     "AMap.PlaceSearch",
//     "AMap.Scale",
//     "AMap.OverView",
//     "AMap.ToolBar",
//     "AMap.MapType",
//     "AMap.PolyEditor",
//     "AMap.CircleEditor",
//     "AMap.Geolocation", // 定位
//   ],
//   // 高德 sdk 版本，默认为 1.4.4
//   v: "1.4.15",
// });
import Vconsole from "vconsole";
const isStage = process.env.VUE_APP_ENV;
  console.log(isStage);
  // if (isStage === "development") {
  // const vConsole = new Vconsole();
  // Vue.use(vConsole);
// }
Vue.use(Popup);
Vue.use(Badge);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Button);
Vue.use(Field);
Vue.use(Overlay); // 遮罩
Vue.use(Skeleton); // 骨架屏
Vue.use(Loading); // 骨架屏
Vue.use(Toast); // 轻提示
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(PullRefresh); // 下拉刷新
Vue.use(List); // vant-list
Vue.use(Picker); // 选择器
Vue.use(Dialog); // 弹出框
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ActionSheet);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ShareSheet);
Vue.use(Empty);
Vue.use(VanImage);
Vue.use(Uploader);
Vue.use(CountDown);
Vue.use(DatetimePicker); // 弹出框
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Sticky);
Vue.use(Switch);
Vue.use(Progress);
Vue.use(Popover);
Vue.use(NumberKeyboard);
Vue.use(Area);
Vue.use(NoticeBar);
Vue.use(TreeSelect);

// Vue.use(ImagePreview); // 图片预览
//将wx js-sdk挂载到原型
Vue.prototype.$wx = wx
//如果有使用开放标签需要在此次设置，忽略vue的警告
Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp']
Vue.prototype.$showLoading = (msg = '加载中...') => {
  Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: msg
  });
};
Vue.prototype.$showUpLoading = () => {
  Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: "上传中..."
  });
};
Vue.prototype.$EventBus = Bus;
Vue.prototype.$ImgBaseUrl = process.env.VUE_APP_BASE_IMG; // 图片服务器地址
Vue.prototype.$BaseUrl = process.env.VUE_APP_BASE_URL;
Vue.prototype.$AppId = process.env.VUE_APP_APP_ID;
Vue.prototype.$hideLoading = Toast.clear;
Vue.prototype.$bridge = require("dsbridge");
// window.addEventListener(
//   "popstate",
//   function(e) {
//     console.log(e);
//     router.isBack = true;
//   },
//   false
// );
Vue.prototype.$pushHistory = function(title) {
  const uuid = generateUUID();
  var state = {
    title: title,
    url: location.hash + "&" + title + "=" + uuid
  };
  window.history.pushState(state, state.title, state.url);
};
import * as filters from "@/utils/filters";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});
import mixin from "@/mixin";
Vue.mixin(mixin);
import App from "./App";
import { router } from "./router";
import store from "./store";

new Vue({
  router,
  el: "#app",
  store,
  render: h => h(App)
});
