/**
 * 隐藏手机号码
 * @param val {Number, String} 转换的字符串对象
 * @param retain {Number} 保留位数
 * @return {String}
 */
export const privatePhone = function(val, retain = 4) {
  if (!Number(val) || String(val).length !== 11 || retain == 0) return val;
  let mobile = String(val);
  let digit = 11 - 3 - retain;
  let reg = new RegExp(`^(\\d{3})\\d{${digit}}(\\d{${retain}})$`);
  return mobile.replace(reg, `$1${"*".repeat(digit)}$2`);
};

const liveStatusDesc = [
  {
    statusName: "已拒绝",
    status: -1
  },
  {
    statusName: "待审核",
    status: 0
  },
  {
    statusName: "审核通过",
    status: 1
  },
  {
    statusName: "已结束",
    status: 2
  },
  {
    statusName: "已取消",
    status: 3
  }
];
export const liveStatusFilter = status => {
  if (!status && status !== 0) {
    return "----";
  }
  let liveStatusName = "";
  liveStatusDesc.map(item => {
    if (item.status === status) {
      liveStatusName = item.statusName;
    }
  });
  return liveStatusName;
};

const videoStatusDesc = [
  {
    statusName: "待审核",
    status: 0
  },
  {
    statusName: "已发布",
    status: 1
  },
  {
    statusName: "已拒绝",
    status: 2
  }
];
export const videoStatusFilter = status => {
  if (!status && status !== 0) {
    return "----";
  }
  let videoStatusName = "";
  videoStatusDesc.map(item => {
    if (item.status === status) {
      videoStatusName = item.statusName;
    }
  });
  return videoStatusName;
};

const topicTypeDesc = [
  {
    statusName: "文章",
    status: 1
  },
  {
    statusName: "话题",
    status: 2
  }
];
export const topicTypeFilter = status => {
  if (!status && status !== 0) {
    return "----";
  }
  let topicTypeName = "";
  topicTypeDesc.map(item => {
    if (item.status === status) {
      topicTypeName = item.statusName;
    }
  });
  return topicTypeName;
};
/**
 * 提取数组中的字段组成字符串
 */
export const ArrtoString = (arr, key) => {
  if (!arr || arr.length === 0) {
    return "暂无处方药品";
  }
  let returnArr = [];
  arr.map(item => {
    returnArr.push(item[key]);
  });
  return returnArr.toString();
};

export const startToEnd = (start, end) => {
  if (!start) {
    return "---- ---- ----";
  }
  return start + " - " + end.substring(10);
};

// 金额分位及浮点转化
export const moneyFormat = num => {
  if (!num) {
    if (num === 0) {
      return "0.00";
    } else {
      return "--";
    }
  }
  // 接受数字变双浮点
  let numToFloat = num.toFixed(2);
  // 分割整数和浮点数
  let num0 = numToFloat.split(".")[0];
  let num1 = numToFloat.split(".")[1];
  num0 = parseInt(num0).toLocaleString();
  return num0 + "." + num1;
};

// 药房距离转化 大于1000m => 1km
export const metre2kilometre = metre => {
  console.log(metre);
  let newValue = "";
  if (metre >= 1000) {
    newValue = (metre / 1000).toFixed() + "公里";
  } else {
    newValue = metre.toFixed() + "米";
  }
  return newValue;
};

// // 时间戳转时间格式
// export const timestamp2Date = (timestamp) => {
//   return timestamp
// }

// 点赞数量转化 大于1000 =>1k+  大于1万 => 10k+
export const counts2ShortCounts = counts => {
  if (!counts) {
    return "0";
  } else {
    let shortCounts = "0";
    if (counts <= 9999) {
      shortCounts = counts;
    } else {
      shortCounts = (counts/10000).toFixed(1)+"万";
    }

    return shortCounts;
  }
};
export const counts2KiloCounts = counts => {
  if (!counts) {
    return "0";
  } else {
    let shortCounts = "0";
    if (counts <= 9999) {
      shortCounts = counts;
    } else {
      shortCounts = "9999+";
    }

    return shortCounts;
  }
};
// 论坛投票题型
export const questionTypeName = type => {
  if (!type) {
    return "----";
  } else {
    let typeName = "";
    switch (type) {
      case 1:
        typeName = "单选";
        break;
      case 2:
        typeName = "多选";
        break;
      case 3:
        typeName = "填空";
        break;
      default:
        break;
    }
    return typeName;
  }
};

// 日期格式转化
export const dateChange = date => {
  if (date) {
    let createTime = date.split(/年|月|日/);
    return createTime[0] + "/" + createTime[1] + "/" + createTime[2] + (createTime[3]?' '+createTime[3]:'');
  } else {
    return "----";
  }
};

export const commentDate= date => {
  if(date){
    let now = new Date()
    let nowYear = now.getFullYear()
    // 判断日期是否包含当前年份,包含则删除
    if(date.search(nowYear+'')!==-1){
       return date.substring(5)
    }else {
      return date
    }
  }
}
export const resultType= type => {
  if(type===2){
    return '已通过'
  }else if(type === 3){
    return '未通过'
  }
}

export const orderStatusFilter = status => {
  let statusArr = [
    '待支付',
    '已支付',
    '订单超时',
    '退款中',
    '已退款',
    '退款失败'
  ]
  return statusArr[status]
}

export const payTypeFilter = status => {
  let statusArr = [
    '',
    '微信公众号支付',
    '微信小程序支付',
  ]
  return statusArr[status]
}