// import Cookies from 'js-cookie'

export function getToken () {
  return getStore('token')
}

export function setToken (token) {
  return setStore('token', token)
}

export function removeToken () {
  return removeStore('token')
}
export function setExpires () {
  let now = new Date(Date.now()).getTime()
  let expires = now + 1000 * 60 * 60 * 10 //1200 分钟
  return setStore('expires', expires)
}
export function getExpires () {
  let now = new Date(Date.now()).getTime()
  let expires = getStore('expires')
  if (now > expires) {
    return true
  } else {
    return false
  }
}
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}