/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  return window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

// 保存cookie
export const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  window.document.cookie =
    name + "=" + value + ";path=/;expires=" + d.toGMTString();
};
// 获得cookie
export const getCookie = (name) => {
  var v = window.document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};
// 删除cookie
export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};

// 日期格式化方法
Date.prototype.format = function (format) {
  var date = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    "S+": this.getMilliseconds(),
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in date) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? date[k]
          : ("00" + date[k]).substr(("" + date[k]).length)
      );
    }
  }
  return format;
};

Date.prototype.getWeekNumber = function () {
  const time = this.getTime();
  let week;
  // this.setDate(this.getDate() + 4 - (this.getDay() || 7));
  this.setMonth(0);
  this.setDate(1);
  week = Math.floor(Math.round((time - this) / 86400000) / 7) + 1;
  return week;
};
// 根据周数，得到日期类型
export const getWeekDate = (year, week) => {
  var d = new Date(year, 0, 1);
  while (d.getDay() != 1) {
    d.setDate(d.getDate() + 1);
  }
  var to = new Date(year + 1, 0, 1);
  var i = 1;
  for (var from = d; from < to; ) {
    if (i == week) {
      return from;
    }
    // alert(from.getDate());
    from.setTime(from.getTime() + 7 * 24 * 60 * 60 * 1000);
    i++;
  }
};

// 根据周数，得到日期类型
export const getMonthDate = (year, month) => {
  var d = new Date(year, month, 0);
  return d;
};

// 生成uuid
export const getUUID = (len, radix) => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23];
    uuid[14] = "4";
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
};

// 合并单元格算法
export const combineCell = (list) => {
  list.sort(function (a, b) {
    if (a.deliveryNum == b.deliveryNum) {
      return 0;
    } else if (a.deliveryNum < b.deliveryNum) {
      return -1;
    } else {
      return 1;
    }
  });
  for (const field in list[0]) {
    let k = 0;
    while (k < list.length) {
      list[k][field + "span"] = 1;
      list[k][field + "dis"] = false;
      let i = 0;
      for (i = k + 1; i <= list.length - 1; i++) {
        if (list[k][field] == list[i][field] && list[k][field] != "") {
          list[k][field + "span"]++;
          list[k][field + "dis"] = false;
          list[i][field + "span"] = 1;
          list[i][field + "dis"] = true;
        } else {
          break;
        }
      }
      k = i;
    }
  }
  return list;
};

/**
 * 获取style样式
 */
export const getStyle = (element, attr, NumberMode = "int") => {
  let target;
  // scrollTop 获取方式不同，没有它不属于style，而且只有document.body才能用
  if (attr === "scrollTop") {
    target = element.scrollTop;
  } else if (element.currentStyle) {
    target = element.currentStyle[attr];
  } else {
    target = document.defaultView.getComputedStyle(element, null)[attr];
  }
  // 在获取 opactiy 时需要获取小数 parseFloat
  return NumberMode == "float" ? parseFloat(target) : parseInt(target);
};

/**
 * 页面到达底部，加载更多
 */
export const loadMore = (element, callback) => {
  const windowHeight = window.screen.height;
  let height;
  let setTop;
  let paddingBottom;
  let marginBottom;
  let requestFram;
  let oldScrollTop;

  document.body.addEventListener(
    "scroll",
    () => {
      loadMore();
    },
    false
  );
  // 运动开始时获取元素 高度 和 offseTop, pading, margin
  element.addEventListener(
    "touchstart",
    () => {
      height = element.offsetHeight;
      setTop = element.offsetTop;
      paddingBottom = getStyle(element, "paddingBottom");
      marginBottom = getStyle(element, "marginBottom");
    },
    {
      passive: true,
    }
  );

  // 运动过程中保持监听 scrollTop 的值判断是否到达底部
  element.addEventListener(
    "touchmove",
    () => {
      loadMore();
    },
    {
      passive: true,
    }
  );

  // 运动结束时判断是否有惯性运动，惯性运动结束判断是非到达底部
  element.addEventListener(
    "touchend",
    () => {
      oldScrollTop = document.body.scrollTop;
      moveEnd();
    },
    {
      passive: true,
    }
  );

  const moveEnd = () => {
    requestFram = requestAnimationFrame(() => {
      if (document.body.scrollTop != oldScrollTop) {
        oldScrollTop = document.body.scrollTop;
        loadMore();
        moveEnd();
      } else {
        cancelAnimationFrame(requestFram);
        // 为了防止鼠标抬起时已经渲染好数据从而导致重获取数据，应该重新获取dom高度
        height = element.offsetHeight;
        loadMore();
      }
    });
  };

  const loadMore = () => {
    if (
      document.body.scrollTop + windowHeight >=
      height + setTop + paddingBottom + marginBottom
    ) {
      callback();
    }
  };
};

/**
 * 显示返回顶部按钮，开始、结束、运动 三个过程中调用函数判断是否达到目标点
 */
export const showBack = (callback) => {
  let requestFram;
  let oldScrollTop;

  document.addEventListener(
    "scroll",
    () => {
      showBackFun();
    },
    false
  );
  document.addEventListener(
    "touchstart",
    () => {
      showBackFun();
    },
    {
      passive: true,
    }
  );

  document.addEventListener(
    "touchmove",
    () => {
      showBackFun();
    },
    {
      passive: true,
    }
  );

  document.addEventListener(
    "touchend",
    () => {
      oldScrollTop = document.body.scrollTop;
      moveEnd();
    },
    {
      passive: true,
    }
  );

  const moveEnd = () => {
    requestFram = requestAnimationFrame(() => {
      if (document.body.scrollTop != oldScrollTop) {
        oldScrollTop = document.body.scrollTop;
        moveEnd();
      } else {
        cancelAnimationFrame(requestFram);
      }
      showBackFun();
    });
  };

  // 判断是否达到目标点
  const showBackFun = () => {
    if (document.body.scrollTop > 500) {
      callback(true);
    } else {
      callback(false);
    }
  };
};

/**
 * 运动效果
 * @param {HTMLElement} element   运动对象，必选
 * @param {JSON}        target    属性：目标值，必选
 * @param {number}      duration  运动时间，可选
 * @param {string}      mode      运动模式，可选
 * @param {function}    callback  可选，回调函数，链式动画
 */
export const animate = (
  element,
  target,
  duration = 400,
  mode = "ease-out",
  callback
) => {
  clearInterval(element.timer);

  // 判断不同参数的情况
  if (duration instanceof Function) {
    callback = duration;
    duration = 400;
  } else if (duration instanceof String) {
    mode = duration;
    duration = 400;
  }

  // 判断不同参数的情况
  if (mode instanceof Function) {
    callback = mode;
    mode = "ease-out";
  }

  // 获取dom样式
  const attrStyle = (attr) => {
    if (attr === "opacity") {
      return Math.round(getStyle(element, attr, "float") * 100);
    } else {
      return getStyle(element, attr);
    }
  };
  // 根字体大小，需要从此将 rem 改成 px 进行运算
  const rootSize = parseFloat(document.documentElement.style.fontSize);

  const unit = {};
  const initState = {};

  // 获取目标属性单位和初始样式值
  Object.keys(target).forEach((attr) => {
    if (/[^\d^.]+/gi.test(target[attr])) {
      unit[attr] = target[attr].match(/[^\d^.]+/gi)[0] || "px";
    } else {
      unit[attr] = "px";
    }
    initState[attr] = attrStyle(attr);
  });

  // 去掉传入的后缀单位
  Object.keys(target).forEach((attr) => {
    if (unit[attr] == "rem") {
      target[attr] = Math.ceil(parseInt(target[attr]) * rootSize);
    } else {
      target[attr] = parseInt(target[attr]);
    }
  });

  let flag = true; // 假设所有运动到达终点
  const remberSpeed = {}; // 记录上一个速度值,在ease-in模式下需要用到
  element.timer = setInterval(() => {
    Object.keys(target).forEach((attr) => {
      let iSpeed = 0; // 步长
      let status = false; // 是否仍需运动
      const iCurrent = attrStyle(attr) || 0; // 当前元素属性址
      let speedBase = 0; // 目标点需要减去的基础值，三种运动状态的值都不同
      let intervalTime; // 将目标值分为多少步执行，数值越大，步长越小，运动时间越长
      switch (mode) {
        case "ease-out":
          speedBase = iCurrent;
          intervalTime = (duration * 5) / 400;
          break;
        case "linear":
          speedBase = initState[attr];
          intervalTime = (duration * 20) / 400;
          break;
        case "ease-in": {
          const oldspeed = remberSpeed[attr] || 0;
          iSpeed = oldspeed + (target[attr] - initState[attr]) / duration;
          remberSpeed[attr] = iSpeed;
          break;
        }
        default:
          speedBase = iCurrent;
          intervalTime = (duration * 5) / 400;
      }
      if (mode !== "ease-in") {
        iSpeed = (target[attr] - speedBase) / intervalTime;
        iSpeed = iSpeed > 0 ? Math.ceil(iSpeed) : Math.floor(iSpeed);
      }
      // 判断是否达步长之内的误差距离，如果到达说明到达目标点
      switch (mode) {
        case "ease-out":
          status = iCurrent != target[attr];
          break;
        case "linear":
          status =
            Math.abs(Math.abs(iCurrent) - Math.abs(target[attr])) >
            Math.abs(iSpeed);
          break;
        case "ease-in":
          status =
            Math.abs(Math.abs(iCurrent) - Math.abs(target[attr])) >
            Math.abs(iSpeed);
          break;
        default:
          status = iCurrent != target[attr];
      }

      if (status) {
        flag = false;
        // opacity 和 scrollTop 需要特殊处理
        if (attr === "opacity") {
          element.style.filter = "alpha(opacity:" + (iCurrent + iSpeed) + ")";
          element.style.opacity = (iCurrent + iSpeed) / 100;
        } else if (attr === "scrollTop") {
          element.scrollTop = iCurrent + iSpeed;
        } else {
          element.style[attr] = iCurrent + iSpeed + "px";
        }
      } else {
        flag = true;
      }

      if (flag) {
        clearInterval(element.timer);
        if (callback) {
          callback();
        }
      }
    });
  }, 20);
};
/**
 * 存储sessionStorage
 */
export const setSessionStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.sessionStorage.setItem(name, content);
};

/**
 * 获取存储sessionStorage
 */
export const getSessionStore = (name) => {
  if (!name) return;
  return window.sessionStorage.getItem(name);
};
/**
 * 删除sessionStorage
 */
export const removeSessionStore = (name) => {
  if (!name) return;
  window.sessionStorage.removeItem(name);
};
/**
 * json转formData
 */
// export const json2FormDataStr = data => {
//   if(!data) return;
//   let newData = "?"

//   const formData = new FormData()
//   for( let index in data){
//     formData.append(index,data[index])
//   }

//   return formData;
// }
export const json2FormDataStr = (data) => {
  if (!data) return;
  let newData = "?";
  for (const index in data) {
    console.log(index);
    if (data[index] === "") {
      delete data[index];
    } else {
      newData += index + "=" + data[index] + "&";
    }
  }
  newData = newData.substring(0, newData.length - 1);
  return newData;
};
export const arr2query = (key, arr) => {
  if (!arr) return;
  let newData = "?";
  arr.map((item) => {
    newData += key + "=" + item + "&";
  });
  newData = newData.substring(0, newData.length - 1);
  return newData;
};
// 根据生日计算年龄
export const birthday2Age = (data) => {
  if (!data) return "暂无";
  // console.log(data)
  data = data.split("日");
  data = data[0].replace(/[年月]/g, "/");
  // console.log(data)
  const d = new Date();
  const birthday = new Date(data);
  var age =
    d.getFullYear() -
    birthday.getFullYear() -
    (d.getMonth() < birthday.getMonth() ||
    (d.getMonth() == birthday.getMonth() && d.getDate() < birthday.getDate())
      ? 1
      : 0);
  return age;
};

// 计算患病天数
export const hasDiseaseDays = (data) => {
  if (!data) return "暂无";
  const d = new Date().getTime();
  const startDay = new Date(data).getTime();
  const oneday = 1000 * 60 * 60 * 24;
  var days = Math.floor((d - startDay) / oneday);
  return days;
};

export const getDateDiff = (dateTimeStamp) => {
  var result;
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  // var halfamonth = day * 15;
  var month = day * 30;
  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return;
  }
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  if (monthC >= 1) {
    if (monthC <= 12) {
      result = "" + parseInt(monthC) + "月前";
    } else {
      result = "" + parseInt(monthC / 12) + "年前";
    }
  } else if (weekC >= 1) {
    result = "" + parseInt(weekC) + "周前";
  } else if (dayC >= 1) {
    result = "" + parseInt(dayC) + "天前";
  } else if (hourC >= 1) {
    result = "" + parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    result = "" + parseInt(minC) + "分钟前";
  } else {
    result = "刚刚";
  }
  return result;
};
export const isIOS = () => {
  let ua = window.navigator.userAgent;
  //$alert('浏览器版本: ' + app + '\n' + '用户代理: ' + ua);
  if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    //ios系统
    return true;
  } else {
    return false;
  }
};
export const iosBlur = () => {
  let ua = window.navigator.userAgent;
  //$alert('浏览器版本: ' + app + '\n' + '用户代理: ' + ua);
  if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    //ios系统
    let currentPosition, timer;
    let speed = 1;
    timer = setInterval(function () {
      currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      currentPosition -= speed;
      window.scrollTo(0, currentPosition); //页面向上滚动
      currentPosition += speed;
      window.scrollTo(0, currentPosition); //页面向下滚动
      clearInterval(timer);
      // alert("失去焦点")
      console.log("失去焦点");
    }, 100);
  }
};
export const heightArr = () => {
  let arr = [];
  for (let i = 20; i < 251; i++) {
    let data = {};
    data.id = i;
    data.name = i + "cm";
    arr.push(data);
  }
  return arr;
};
export const weightArr = () => {
  let arr = [];
  for (let i = 10; i < 251; i++) {
    let data = {};
    data.id = i;
    data.name = i + "kg";
    arr.push(data);
  }
  return arr;
};
// 计算当前秒数字符串
export const currentSecend = () => {
  let secend = new Date().getTime().toString();
  secend = secend.substr(0, secend.length - 3);
  return secend;
};

// 检测微信环境
export const isWechatBroswer = () => {
  var ua = navigator.userAgent.toLowerCase();
  var version = 0;
  var micromessenger = "micromessenger";
  if (ua.match(/MicroMessenger/i) == micromessenger) {
    var verStr = ua.substring(
      ua.indexOf(micromessenger) + micromessenger.length,
      ua.indexOf(micromessenger) + micromessenger.length + 2
    );
    version = parseInt(verStr.split("/")[1]);
    if (version < 5) {
      // alert("本页面仅适用于微信浏览器版本5.0及以上");
      // console.log()
      return false;
    } else {
      // alert("您的微信浏览器可以使用");
      return true;
    }
  } else {
    // alert("请使用微信内置浏览器打开本页面");
    return false;
  }
};
/**
 * 防抖函数
 * fu 延时调用函数
 * delay 延迟多长时间
 */
export const _debounce = (fn, delayTime) => {
  let delay = delayTime || 200;
  let timer;
  return function () {
    let th = this;
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
};

/**
 * 节流函数
 * fu 延时调用函数
 * delay 延迟多长时间
 * atleast 至少多长时间触发一次
 */
export const _throttle = (fn, delay, atleast) => {
  let timer = null;
  let previous = null;
  return function () {
    let now = +new Date();
    if (!previous) previous = now;
    if (atleast && now - previous > atleast) {
      fn();
      previous = now;
      clearTimeout(timer);
    } else {
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn();
        previous = null;
      }, delay);
    }
  };
};

/**
 * 计算当前时间
 * 并生成接下来24小时的
 * 有bug,暂时弃用
 */
// export const deliveryTime = () => {
//   let label = "今天 ";
//   let currentDate = new Date();
//   let todayFormat = currentDate.format("yyyy-MM-dd hh:mm"); // '2020-10-10 20:00'
//   let todayTime = todayFormat.substring(0, 10); // '2020-10-10'
//   let todayTimeString = new Date(todayTime + " 00:00"); // 1602288000000
//   let tomorrowTimeString = todayTimeString + 24 * 3600 * 1000;
//   let tomorrowFormat = new Date(tomorrowTimeString).format("yyyy-MM-dd hh:mm");
//   let tomorrowTime = tomorrowFormat.substring(0, 10);
//   let currentHours = currentDate.getHours();
//   let currentMinutes = currentDate.getMinutes();
//   console.log(1111);
//   if (currentMinutes > 30) {
//     // 当前小时+1
//     if (currentHours < 23) {
//       currentHours++;
//     } else {
//       currentHours = 0;
//       label = "明天 ";
//     }
//   }
//   // 计算未来24小时picker选项
//   let pickerArr = [];
//   for (let index = 0; index < 24; index++) {
//     const item = {};
//     let textHours = currentHours + index * 1;
//     let sendTimeStart =
//       textHours < 24
//         ? (textHours < 10 ? "0" + textHours : textHours) + ":00"
//         : (textHours - 24 < 10 ? "0" + (textHours - 24) : textHours - 24) +
//           ":00";
//     let sendTimeEnd =
//       textHours + 1 < 24
//         ? (textHours + 1 < 10 ? "0" + (textHours + 1) : textHours + 1) + ":00"
//         : (textHours + 1 - 24 < 10
//             ? "0" + (textHours + 1 - 24)
//             : textHours + 1 - 24) + ":00";
//     item.text = label + sendTimeStart + " - " + sendTimeEnd;
//     if (label === "今天") {
//       item.sendTimeStart = todayTime + " " + sendTimeStart;
//       item.sendTimeEnd = todayTime + " " + sendTimeEnd;
//     } else {
//       item.sendTimeStart = tomorrowTime + " " + sendTimeStart;
//       item.sendTimeEnd = tomorrowTime + " " + sendTimeEnd;
//     }
//     pickerArr.push(item);
//     if (textHours === 23 && index !== 0) {
//       label = "明天 ";
//     }
//   }
//   return pickerArr;
// };
/**
 * 计算当前时间
 * 并生成当天12小时的选项
 *
 */
export const deliveryTime = () => {
  let label = "今天 ";
  let currentDate = new Date();
  let todayFormat = currentDate.format("yyyy-MM-dd hh:mm"); // '2020-10-10 20:00'
  let todayTime = todayFormat.substring(0, 10); // '2020-10-10'
  let todayTimeString = new Date(todayTime + " 00:00"); // 1602288000000
  let tomorrowTimeString = todayTimeString + 24 * 3600 * 1000;
  let tomorrowFormat = new Date(tomorrowTimeString).format("yyyy-MM-dd hh:mm");
  let tomorrowTime = tomorrowFormat.substring(0, 10);
  let currentHours = currentDate.getHours();
  let currentMinutes = currentDate.getMinutes();
  console.log(currentMinutes);
  // if (currentMinutes > 30) {
  //   // 当前小时+1
  //   if (currentHours < 23) {
  //     currentHours++;
  //   } else {
  //     currentHours = 0;
  //     label = "明天 ";
  //   }
  // }
  // 计算未来24小时picker选项
  let pickerArr = [];
  for (let index = 0; index < 12; index++) {
    const item = {};

    let textHours = 8 + index * 1;
    if (textHours < currentHours) {
      item.disabled = true;
    }
    let sendTimeStart =
      textHours < 24
        ? (textHours < 10 ? "0" + textHours : textHours) + ":00"
        : (textHours - 24 < 10 ? "0" + (textHours - 24) : textHours - 24) +
          ":00";
    let sendTimeEnd =
      textHours + 1 < 24
        ? (textHours + 1 < 10 ? "0" + (textHours + 1) : textHours + 1) + ":00"
        : (textHours + 1 - 24 < 10
            ? "0" + (textHours + 1 - 24)
            : textHours + 1 - 24) + ":00";
    item.text = label + sendTimeStart + " - " + sendTimeEnd;
    if (label === "今天") {
      item.sendTimeStart = todayTime + " " + sendTimeStart;
      item.sendTimeEnd = todayTime + " " + sendTimeEnd;
    } else {
      item.sendTimeStart = tomorrowTime + " " + sendTimeStart;
      item.sendTimeEnd = tomorrowTime + " " + sendTimeEnd;
    }
    pickerArr.push(item);
    if (textHours === 23 && index !== 0) {
      label = "明天 ";
    }
  }

  return pickerArr;
};
/**
 *
 * @param {Object} data
 * @return Boolean
 */
export const isBlank = (data) => {
  return JSON.stringify(data) == "{}";
};

//计算视频时长
export const calcVideoDuration = (time) => {
  var totalSecond = Math.floor(time);
  // 得到分钟数加秒数
  if (totalSecond < 60) {
    let second = totalSecond % 60;
    if (second < 10) {
      second = "0" + second;
    }
    return "00:00:" + second;
  } else if (totalSecond < 3600) {
    let minute = Math.floor(totalSecond / 60);
    if (minute < 10) {
      minute = "0" + minute;
    }
    let second = totalSecond % 60;
    if (second < 10) {
      second = "0" + second;
    }
    return "00:" + minute + ":" + second;
  } else {
    let hours = Math.floor(totalSecond / 3600);
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minute = Math.floor((totalSecond % 3600) / 60);
    if (minute < 10) {
      minute = "0" + minute;
    }
    let second = Math.floor((totalSecond % 3600) % 60);
    if (second < 10) {
      second = "0" + second;
    }
    return hours + ":" + minute + ":" + second;
  }
};
