import { wxlogin,getSignature } from "@/api/login";
import { getMeetingDetail } from "@/api/meeting";

import { setStore, getStore,removeStore } from "@/utils/mUtils";
// import { setToken } from "@/utils/auth";
import { Toast } from "vant";
import { mapGetters } from "vuex";
// import { debounce } from "@/utils/debounce";
let MIXIN = {
  data() {
    // const now = new Date();
    // const future = new Date(now.getFullYear() + 3, 11, 31);
    return {
      userInfo: {}
    };
  },
  created() {
    // console.log(now);
  },
  mounted() {},
  methods: {
    // debounce,
    // ios禁止滚动
    iosScroll(className){
      var startY = 0;
		var scrollBox = document.querySelector(`.${className}`);
    console.log(scrollBox);
		document.body.addEventListener('touchstart', function(e){
			startY = e.touches[0].pageY;
		}, { passive: false });
 
		document.body.addEventListener('touchmove', function(e) {
			var moveY = e.touches[0].pageY;
			var top = scrollBox.scrollTop;
			var ch = scrollBox.clientHeight;
			var sh = scrollBox.scrollHeight;
			if (!isChildTarget(e.target, scrollBox)) {
				e.preventDefault();
			} else if ((top === 0 && moveY > startY) || (top + ch === sh && moveY < startY)) {
				e.preventDefault();
			}
		}, { passive: false });
		function isChildTarget(child, parent, justChild) {
			// justChild为true则只判断是否为子元素，若为false则判断是否为本身或者子元素 默认为false
			var parentNode;
			if (justChild) {
				parentNode = child.parentNode;
			} else {
				parentNode = child;
			}
 
			if (child && parent) {
				while (parentNode) {
					if (parent === parentNode) {
						return true;
					}
					parentNode = parentNode.parentNode;
				}
			}
			return false;
		}
    },
    // 配置weixin-js-sdk
    async initWXJSSDK(injectData) {
      // 初始化微信JSSDK
      let { timestamp, nonceStr, signature,url } = JSON.parse(
        getStore("signatureInfo")
      )
        ? JSON.parse(getStore("signatureInfo"))
        : {};
      const isDiffHref = location.href !== url
      const isExpired = !signature ? true : this.isSignatureExpired();
      // console.log("signature是否过期", isExpired);
      if (isDiffHref||isExpired) {
        removeStore("signatureInfo");
        url = location.href
        const { data } = await getSignature({url});
        timestamp = data.timestamp;
        nonceStr = data.nonceStr;
        signature = data.signature;
        setStore("signatureInfo", {...data,url});
      }
      let config = {
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出
        // debug: this.$WxDebug,
        appId: this.$AppId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ["updateAppMessageShareData","updateTimelineShareData"], // 必填，需要使用的JS接口列表
      };
      //配置SDK
      this.$wx.config(config);
      this.$wx.ready(() => {
        if(injectData){
          let {title,desc,link,imgUrl} = injectData
          console.log("wxsdk ready");
          this.$wx.updateAppMessageShareData({
            title,
            desc,
            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl, // 分享图标
            success: () => {
              // 设置成功
            }
          });
          this.$wx.updateTimelineShareData({ 
            title,
            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl, // 分享图标
            success: function () {
              // 设置成功
            }
          })
        }else{
        }
      });
    },
    async initWXJSSDKNoShare() {
      // 初始化微信JSSDK
      let { timestamp, nonceStr, signature,url } = JSON.parse(
        getStore("signatureInfo")
      )
        ? JSON.parse(getStore("signatureInfo"))
        : {};
      // console.log(timestamp);
      // console.log(nonceStr);
      // console.log(signature);
      // console.log(this.isSignatureExpired);
      const isDiffHref = location.href !== url
      const isExpired = !signature ? true : this.isSignatureExpired();
      // console.log("signature是否过期", isExpired);
      if (isDiffHref||isExpired) {
        removeStore("signatureInfo");
        const { data } = await getSignature({url:location.href});
        timestamp = data.timestamp;
        nonceStr = data.nonceStr;
        signature = data.signature;
        url = location.href
        setStore("signatureInfo", {...data,url});
      }
      let config = {
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出
        // debug: this.$WxDebug,
        appId: this.$AppId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ["hideOptionMenu"], // 必填，需要使用的JS接口列表
      };
      //配置SDK
      this.$wx.config(config);
      this.$wx.ready(() => {
        console.log("wxsdk ready");
        this.$wx.hideOptionMenu();
        // this.$wx.updateAppMessageShareData({ 
        //   title: '', // 分享标题
        //   desc: '', // 分享描述
        //   link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //   imgUrl: '', // 分享图标
        //   success: function () {
        //     // 设置成功
        //   }
        // })
      });
    },
    // 更新用户信息
    async getUserInfoFunc() {
      this.userInfo = JSON.parse(getStore("userInfo"));
      if (this.userInfo) {
        // console.log(this.userInfo);
      } else {
        const res = await wxlogin();
        this.userInfo = res.data;
        // setToken(this.userInfo.accessToken)
        setStore("userInfo", JSON.stringify(this.userInfo));
      }
    },
    handleVisiable(e) {
      // 监听页面离开事件
      if (e.target.visibilityState === "visible") {
        console.log("回来了,刷新列表");
        this.onRefresh();
      } else {
        console.log("离开了");
      }
    },
    onOversize(file) {
      console.log(file);
      Toast("图片大小不能超过 5M");
    },
    onVideoOversize(file) {
      console.log(file);
      Toast("上传视频限制500M，请重新选择");
    },
    // 裁剪取消
    canceltailor() {
      this.fileList = [];
    },
    // 校验图片格式
    checkImgAccept(file, typeArr) {
      console.log(file);
      let defaultTypeArr = ["jpeg", "png"];
      if (typeArr && typeArr.length > 0) {
        defaultTypeArr = defaultTypeArr.concat(typeArr);
      }
      let message = "请选择 " + defaultTypeArr.join(" 或 ") + " 格式图片";
      if (defaultTypeArr.includes(file.type.split("/")[1])) {
        return true;
      } else {
        Toast(message);
        return false;
      }
    },
    // 校验全屏状态
    checkFull() {
      var isFull =
        document.fullscreenEnabled ||
        window.fullScreen ||
        document.webkitIsFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) isFull = false;
      return isFull;
    },
    // 保存未发布的评论
    saveTempMessage(level,contentId,message,reply){
      if(level===1){
        setStore(`tempMessage-contentId${contentId}-0`,message)
      }else{
        setStore(`tempMessage-contentId${contentId}-${reply.id}`,message)
      }
    },
    // 获取未发布的评论
    getTempMessage(level,contentId,reply){
      if(level===1){
        return getStore(`tempMessage-contentId${contentId}-0`)
      }else{
        return getStore(`tempMessage-contentId${contentId}-${reply.id}`)
      }
    },
    // 删除已发布的评论
    deleteTempMessage(level,contentId,reply){
      // console.log(level,contentId,reply);
      if(level===1){
        removeStore(`tempMessage-contentId${contentId}-0`)
      }else{
        removeStore(`tempMessage-contentId${contentId}-${reply.id}`)
      }
    },
    isSignatureExpired(){
      let isExpired;
      const expireTime = (JSON.parse(getStore("signatureInfo")).timestamp+7200)*1000;
      console.log('过期时间',expireTime)
      isExpired =  Date.now() >= expireTime;
      console.log('是否过期',isExpired)
      return isExpired
    },
    // 获取报名状态 isSign 0:未报名,1:已报名
    async isSignCheck(mid){
      console.log(mid);
      const { data }  = await getMeetingDetail({id:mid})
      console.log(data?.isSign);
      const isSign = data?.isSign||false;
      isSign===1?true:false
      if(isSign===1){
        return true
      }else{
        return false
      }
    },
  }
};
export default MIXIN;
