/*
 * @Author: jiameng15
 * @Date: 2021-07-09 09:38:43
 * @Last Modified by: jiameng15
 * @Last Modified time: 2021-09-24 09:44:56
 * 用户相关接口
 */

import request from "@/utils/request"; // 引入request,基于axios
import queryString from "querystring"; // post方法传参转型String

export function getMeetingPage(data) {
  return request({
    url: "/meeting/page",
    method: "get",
    params: data
  });
}
export function getMeetingDetail(data) {
  return request({
    url: "/meeting/detail",
    method: "post",
    data: queryString.stringify(data)
  });
}
export function getMeetingDetails(data) {
  return request({
    url: "/meeting/detail/public",
    method: "post",
    data: queryString.stringify(data)
  });
}
export function getMeetingSign(data) {
  return request({
    url: "/meeting/sign",
    method: "post",
    data: queryString.stringify(data)
  });
}
// 扫码签到
export function checkQrCode(data) {
  return request({
    url: "/check/code",
    method: "post",
    data: queryString.stringify(data)
  });
}