import Vue from "vue";
import Bus from "../Bus";
import Router from "vue-router";
import { setStore, getStore, setSessionStore } from "../utils/mUtils";
Vue.use(Router);
const Detail = {
  path: "detail",
  name: "HomeDetail",
  component: () => import("../views/home/detail.vue"),
  meta: {
    title: "课程详情",
  },
};
const Lesson = {
  path: "lesson",
  name: "HomeDetail2",
  component: () => import("../views/home/detail.vue"),
  meta: {
    title: "课程详情",
  },
};
const orderDetail = {
  path: "detail",
  name: "OrderDetail",
  component: () => import("../views/my/orderDetail.vue"),
  meta: {
    title: "订单详情",
  },
};

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/welcome",
    name: "loginInfo",
    component: () => import("../views/login/welcome"),
    meta: {
      title: "聆课",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
    meta: {
      title: "首页",
    },
    children: [Detail, Lesson],
  },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/my"),
    meta: {
      title: "我的",
    },
    children: [
      {
        path: "info",
        name: "MyInfo",
        component: () => import("@/views/my/info.vue"),
        meta: {
          title: "修改信息",
        },
      },
      {
        path: "myCourses",
        name: "myCourses",
        component: () => import("@/views/my/myCourses.vue"),
        meta: {
          title: "我的课程",
        },
        children: [Detail],
      },
      {
        path: "myOrder",
        name: "myOrder",
        component: () => import("@/views/my/myOrder.vue"),
        meta: {
          title: "我的订单",
        },
        children: [orderDetail],
      },
    ],
  },
  {
    path: "/noAuth/401",
    name: "noAuth",
    component: () => import("../views/noAuth/index"),
    meta: {
      title: "无权访问",
      isleftarrow: true,
    },
  },
  {
    path: "/noAuth/notWx",
    name: "notWx",
    component: () => import("../views/noAuth/notWx"),
    meta: {
      title: "非微信环境",
      isleftarrow: true,
    },
  },
  {
    path: "/notFound/404",
    name: "notFound",
    component: () => import("../views/404/index"),
    meta: {
      title: "页面失联",
      isleftarrow: true,
    },
  },
  {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/notFound/404",
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});
// console.log(routes);
const router = new Router({
  mode: "history",
  base: process.env.VUE_APP_H5,
  routes,
});
// console.log('看看router', router);
router.beforeEach((to, from, next) => {
  if (
    getStore("token") ||
    to.name == "login" || // 测试用
    to.name == "loginInfo" || // 测试用
    to.name == "notFound" ||
    to.name == "noAuth" ||
    to.name == "home" || // 测试用
    to.name == "TestVideo" || // 测试视频
    to.name == "MyInfo" || // 用户信息编辑
    to.name == "HomeDetail" ||
    to.name == "HomeDetail2"
  ) {
    if (to.path === "/") {
      // 假设token存在,且没过期
      if (getStore("token")) {
        router.push({
          path: "/home",
        });
        next();
      }
      console.log(to);
      console.log(location.href);
      // 处理code,先分割#,在分割code=
      // if (location.href.search('code=') > -1 && location.href.search('&state=') > -1) {
      //   const originStr = location.origin + process.env.VUE_APP_H5 ? '/h5' : '' //项目在同域名下的h5文件夹中配置此项
      //   const code = location.href.split('#')[0].split(originStr)[1]
      //   location.href = originStr + '/#' + code
      //   console.log(code);
      //   // next({
      //   //   path: '/welcome',
      //   //   query: {code:code}
      //   // });
      // }
    }
    if (to.name == "HomeDetail") {
      // 记录detail地址
      setSessionStore("REDIRECT_INFO", {
        path: "/home/detail",
        query: to.query,
      });
    }
    if (to.name == "HomeDetail2") {
      // 记录detail地址
      setSessionStore("REDIRECT_INFO", {
        path: "/home/lesson",
        query: to.query,
      });
    }
    const title = to.meta && to.meta.title;
    if (title) {
      document.title = title;
    }
    next();
  } else {
    next({
      path: "/noAuth/401",
    });
  }
});

export { router };
