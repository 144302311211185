import axios from "axios";
import Vue from "vue";
const $EventBus = new Vue();
// console.log($EventBus);
import { Toast, Dialog } from "vant";
// import store from '@/store'
import { getToken } from "@/utils/auth";
// import { resetRouter } from '@/router'
import { router } from "@/router";
import { removeStore, setStore } from "./mUtils";
const bridge = require("dsbridge");
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 0, // request timeout
});
const closePage = function () {
  bridge.call("exitWeb");
};
const whiteList = ["/mine/info", "/alivod/videoPlayAuth"];

// request interceptor
service.interceptors.request.use(
  (config) => {
    console.log("看看config", config);

    // do something before request is sent
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if (getToken() != null && getToken() != undefined && getToken() != "") {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const isWhite = whiteList.includes(response.config.url);
    const res = response.data;
    console.log("看看isWhite", isWhite);
    if (res.code === 1 || isWhite) {
      return res;
    } else if (res.code === -401 || res.code === -1 || res.code === 0) {
      // resetRouter()
      // localStorage.clear();
      // this.$router.push(`/login`);
      // Toast({
      //   message: res.msg,
      //   type: "fail"
      // });
      // Toast.clear();
      //
      // if(res.code === 0&&res.msg === "暂不支持的图片格式"){
      //   return res;
      // }
      // 退出根页面
      if (res.msg === "访问令牌过期或无效" || res.msg === "未登录") {
        // Toast({
        //   message: res.msg,
        //   type: "fail"
        // });
        Dialog.alert({
          confirmButtonText: "去登录",
          title: " ",
          message: res.msg,
        }).then(() => {
          removeStore("token");
          router.push("/welcome");
          // closePage();
          // on close
        });
        return Promise.reject();
      }

      Toast({
        message: res.msg || "Error",
        // type: "fail",
        duration: 1500,
      });

      return Promise.reject(new Error(res.msg || "Error"));
    }
    // if(res.msg === '该账号已存在'){
    //   this.$message({
    //     message: "令牌错误，请重新登录",
    //     type: "error"
    //   });
    // }
    // if the custom code is not 20000, it is judged as an error.
    else {
      Toast({
        message: res.msg || "Error",
        // type: "fail",
        duration: 1500,
      });

      return Promise.reject(new Error(res.msg || "Error"));
    }
    // return res;
  },
  (error) => {
    // console.log(router); // for debug
    // console.log("err" + error); // for debug
    Toast({
      message: error.message,
      // type: "fail",
      duration: 1500,
    });
    return Promise.reject(error);
  }
);

export default service;
