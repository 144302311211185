<template>
  <div>
    <van-tabbar
      v-model="active"
      active-color="#96652D"
      class="ignore"
      :before-change="onBeforeChange"
    >
      <van-tabbar-item
        v-for="(item, index) in tabbars"
        :key="index"
        :to="item.name"
      >
        <span>{{ item.title }}</span>
        <div slot="icon" slot-scope="props">
          <template>
            <img :src="props.active ? item.active : item.normal" />
          </template>
        </div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import { getMyInfo } from "@/api/login";
import { setSessionStore } from "@/utils/mUtils";

export default {
  name: "tabbar",
  props: {
    activeBar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      title: "",
      userInfo: {},
      active: this.activeBar,
      tabbars: [
        {
          name: "/home",
          title: "首页",
          normal: require("@/assets/images/home.png"),
          active: require("@/assets/images/homeActive.png"),
        },
        {
          name: "/my",
          title: "我的",
          normal: require("@/assets/images/my.png"),
          active: require("@/assets/images/myActive.png"),
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/home") {
        this.active = 0;
      } else {
        this.active = 1;
      }
    },
  },
  methods: {
    async onBeforeChange(index) {
      console.log(index);
      if (index === 1) {
        // 去我的页面
        if (await this.checkToken()) {
          return true;
        } else {
          setSessionStore("REDIRECT_INFO", {
            path: "/my",
          });
          this.$router.push({
            path: "/welcome",
          });
          return false;
        }
      } else {
        return true;
      }
    },
    async checkToken() {
      const res = await getMyInfo();
      console.log("校验token是否有效", res);
      //校验token是否有效
      if (res.code === 1) {
        return true;
      } else {
        return false;
      }
    },
    async getUserIdInfo() {
      const res = await getUserInfoById(this.userInfo.userId);
      console.log(res);
      const { data } = res;
      this.userInfo = data;
      if (data) {
      }
      this.title = `${data.nickname}`;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.ignore {
  height: 86px;
  z-index: 999;
}
.van-tabbar {
  border-top: 1px solid #eee;
  background-color: #ffffff;
  .van-tabbar-item__icon img {
    margin-top: -24px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
  }
  .plus {
    position: relative;
    top: -70px;
    width: 70px;
    height: 150px;
    border-radius: 75px;
    overflow: hidden;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    .plus_img {
      width: 110px;
      height: 110px;
      border-radius: 60px;
      background: linear-gradient(230deg, #9bbbff 0%, #6c87fd 100%);
      box-shadow: 0px 0px 10px 8px #cadfff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.active_tab img {
  width: 50px;
  height: 50px;
}
.tabbar_item {
  ::v-deep {
    .van-badge__wrapper {
      display: block;
    }
  }
}
</style>
