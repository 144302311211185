/*
 * @Author: jiameng15
 * @Date: 2020-10-10 14:21:34
 * @Last Modified by: jiameng15
 * @Last Modified time: 2024-07-02 15:19:59
 * 聆课-登录及个人信息
 */

import request from "@/utils/request"; // 引入request,基于axios
import queryString from "querystring"; // post方法传参转型String
// 登录(测试)
export function login(data) {
  return request({
    url: "/login/test",
    method: "post",
    data: queryString.stringify(data)
  });
}
// 微信登录
export function wxlogin(data) {
  return request({
    url: "/login/wx",
    method: "post",
    data: queryString.stringify(data)
  });
}
// 首页信息
export function getHomeInfo() {
  return request({
    url: "/home/info",
    method: "get",
  });
}
// 我的信息
export function getMyInfo() {
  return request({
    url: "/mine/info",
    method: "get",
  });
}
// 修改个人信息
export function updateMyInfo(data) {
  return request({
    url: "/mine/update/info",
    method: "post",
    data: queryString.stringify(data)
  });
}
// 验证码
export function bindPhone(data) {
  return request({
    url: "/bind/phone",
    method: "post",
    data: queryString.stringify(data)
  });
}

// 验证码
export function getCaptcha(data) {
  return request({
    url: "/captcha/send",
    method: "post",
    data: queryString.stringify(data)
  });
}
// 签名信息
export function getSignature(data) {
  return request({
    url: "/wx/share/signature",
    method: "post",
    data: queryString.stringify(data)
  });
}
